import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");
const homeRouter = {
  path: "/",
  name: "home",
  component: Layout,
  redirect: "/welcome",
  keepAlive: true,
  meta: {
    icon: "box",
    title: $t("menus.hshome"),
    rank: 0
  },
  children: [
    {
      path: "/welcome",
      name: "welcome",
      component: () => import("/@/views/welcome/welcome.vue"),
      meta: {
        title: $t("menus.welcome"),
        roles: ["user"]
      }
    }
  ]
};
export default homeRouter;
// {
//   path: "/welcome/categorylist",
//   name: "categoryList",
//   component: () => import("/@/views/welcome/categoryList.vue"),
//   meta: {
//     title: $t("menus.categoryList"),
//     showLink: true
//   }
// },
