import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");

const assetsManageRouter = {
  path: "/assetsManageRouter",
  component: Layout,
  redirect: "/assetsManageRouter/balance",
  hideInMenu: true,
  meta: {
    icon: "money",
    title: $t("menus.assetsManage"),
    rank: 1
  },
  children: [
    {
      path: "/assetsManageRouter/payoneer",
      name: "payoneerRecharge",
      component: () => import("/@/views/assetsmanage/payoneerRecharge.vue"),
      meta: {
        title: $t("menus.payoneerRecharge"),
        roles: ["user"]
      }
    },
    {
      path: "/assetsManageRouter/rechargeInfo",
      name: "rechargeWays",
      component: () => import("/@/views/assetsmanage/rechargeways.vue"),
      meta: {
        title: $t("menus.rechargeInfo"),
        roles: ["user"]
      }
    },
    {
      path: "/assetsManageRouter/balance",
      name: "assetsManageRouter",
      component: () => import("/@/views/assetsmanage/balanceflowing.vue"),
      meta: {
        title: $t("menus.assetsManage"),
        roles: ["user"]
      }
    }
  ]
};

export default assetsManageRouter;
