import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");

const authorizationCenterRouter = {
  path: "/authorizationCenter",
  component: Layout,
  redirect: "/authorizationCenter/authorizationList",
  hideInMenu: true,
  meta: {
    icon: "unlock",
    title: $t("menus.authorizationCenter"),
    rank: 1
  },
  children: [
    {
      path: "/authorizationCenter/authorizationList",
      name: "authorizationCenter",
      component: () =>
        import("/@/views/authorizationcenter/authorizationlist.vue"),
      meta: {
        title: $t("menus.ebayAccount"),
        roles:['user']
      }
    },
    {
      path: "/authorizationCenter/amazonList",
      name: "amazonAccount",
      component: () => import("/@/views/authorizationcenter/amazonlist.vue"),
      meta: {
        title: $t("menus.amazonAccount"),
        roles:['user']
      }
    }
  ]
};

export default authorizationCenterRouter;
