buttons:
  hsLoginOut: 退出系统
  hsfullscreen: 全屏
  hsexitfullscreen: 退出全屏
  hsrefreshRoute: 刷新路由
  hslogin: 登陆
  hsregist: 注册
  hsadd: 新增
  hsmark: 标记/取消
  hssave: 保存
  hssearch: 搜索
  hsexpendAll: 全部展开
  hscollapseAll: 全部折叠
  hssystemSet: 打开项目配置
  hsdelete: 删除
  hsclose: 关闭
  hsreload: 重新加载
  hscloseCurrentTab: 关闭当前标签页
  hscloseLeftTabs: 关闭左侧标签页
  hscloseRightTabs: 关闭右侧标签页
  hscloseOtherTabs: 关闭其它标签页
  hscloseAllTabs: 关闭全部标签页
menus:
  hshome: 首页
  resultEbaySuccess: 结果页
  resultAmazonSuccess: 亚马逊授权结果
  templatePreview: 模板预览页
  noviceGuide: 入门指南
  processIntroduction: 流程介绍
  guidanceVideo: 指导视频
  categoryList: 目录&搜索
  selectGoods: 分销选品
  welcome: 分销选品
  welcomeNew: 分销选品(新)
  goodsManage: 商品管理
  batchSkuMapping: 批量添加SKU映射
  skuMappingList: SKU映射管理
  inventoryPolicy: 库存策略设置
  addSkuMapping: 添加SKU映射
  goodsList: 商品列表
  editGoodsDetails: 商品详情编辑
  viewDetails: 商品预览
  ordersManage: 订单管理
  addOrder: 新建订单
  editOrder: 编辑订单
  batchAddOrders: 批量新建订单
  customerService: 售后服务
  authorizationCenter: 授权中心
  ebayAccount: eBay店铺
  amazonAccount: Amazon店铺
  userCenter: 个人中心
  operationAssistant: ChatGPT AI
  chatGPT: ChatGPT智能助手
  fivePointDescription: 五点描述
  writeTitle: 撰写标题
  productDescription: 产品描述
  invitationComment: 邀评信生成
  replyCustomers: 回复客户
  warningAndSelling: 警告跟卖
  complaintLetter: 写申诉信
  payoneerRecharge: Payoneer 充值
  assetsManage: 资产管理
  rechargeInfo: 线下充值
  messageCenter: 消息中心
  messageList: 消息列表
  platformNotification: 平台通知
  businessAlert: 业务预警
  sentMessage: 已发送消息
  addMessage: 站内通知
  modifyVersion: 调试页面
  hslogin: 登陆
  hsregist: 注册
  hsforgetPassword: 忘记密码
  hserror: 错误页面
  hsfourZeroFour: "404"
  hsfourZeroOne: "403"
  hsFive: "500"
  permission: 权限管理
  permissionPage: 页面权限
  permissionButton: 按钮权限
status:
  hsLoad: 加载中...
loginPage:
  startGuide: 入门指南
  customerService: 联系客服
  welcomeToLogin: 欢迎登录
  email: 邮箱
  password: 密码
  login: 登录
  forgotPassword: 忘记密码
  registerNow: 现在注册
  copyRight: 版权所有
  distributionManagement: 分销管理中心
  loginSucceeded: 登录成功
  loginWarning: 您还不是分销商，请与分销商经理联系
registPage:
  obtainVerificationCode: 获取验证码
  individualRegistration: 个人注册
  corporateRegistration: 企业注册
  verificationCodeSent: 已发送验证码
  pleaseLogInToEmailViewCode: 请稍后登录邮箱查看验证码
  codeHasBeenSentToEmail: 验证码已发送至邮箱：
  ok: 好的
  secondsResend: 秒后重新发送
  resentVerificationCode: 重新发送验证码
  loginSuccessful: 注册成功！
  inputYourName: 请输入您的名字
  checkFirstNameWordsNumber: 名字汉字不超过6个，英文字母不超过12个。
  inputYourLastName: 请输入您的姓氏
  checkLastNameWordsNumber: 姓氏汉字不超过6个，英文字母不超过12个。
  inputYourPhoneNumber: 请输入手机号码
  inputCorrecPhoneNumber: 请输入正确的手机号码
  setPassword: 请设置登录密码
  cantSetPasswordToSpecialCharacters: 密码不能设置为纯特殊字符
  checkPassword: 密码应由6-18位字母、数字或特殊字符组成
  inputConfirmationPassword: 请输入确认密码
  twoPasswordsNotMatch: 两次输入密码不一致
  inputEmailVerificationCode: 请输入邮箱验证码
  checkEmailVerificationCode: 邮箱验证码至少由四位数字组成
  checkBusinessLicense: 您还没有上传营业执照
  uploadSuccessful: 上传成功！
  uploadFailed: 上传失败！
  inputEmailAddress: 请输入完整的邮箱地址
  incorrectEmailFormat: 邮箱格式不正确
  contactName: 联系人姓名
  firstName: 名字
  inputFirstName: 请填写名字
  lastName: 姓氏
  inputLastName: 请填写姓氏
  inputEmail: 请填写邮箱地址
  verifyCode: 验证码
  password: 密码
  inputLoginPassword: 请填写登录密码
  confirmPassword: 确认密码
  inputLoginPasswordAgain: 请再次填写登录密码
  phoneNumber: 手机号
  companyName: 公司名称
  inputCompanyName: 请输入公司名称
  companyAddress: 公司地址
  inputCompanyAddress: 请填写公司地址
  companyBusinessLicense: 营业执照
  register: 注册
  hasAccountGoLogin: 已有帐号，去登录
  scanningQRCodeOnWeChat: 使用微信扫描二维码可以关注审核进度。
forgetPage:
  submit: 提交
  goLogin: 去登录
selectionPage:
  RetailingNSelection: 分销选品
  keyword: 关键字
  productOrItemCode: 产品 Item Code / 英文名称（模糊）
  accountToPublish: 刊登帐号
  chooseAccountToPublish: 请选择刊登帐号
  category: 类别
  broadCategory: 大类
  auto: 汽配
  home: 家居
  allCategorys: 所有类别
  chooseOneCategory: 请选择一个类别
  chooseOneParentCategory: 请先选择一个父类别
  hotCategory: 热门
  inventory: 库存
  minQty: 低值
  maxQty: 高值
  retailingPrice: 分销价
  lowestPrice: 低值
  highestPrice: 高值
  productDownload: 商品信息下载
  chooseDownloadedOrNot: 请选择是否已下载过
  downloaded: 下载过
  notDownLoaded: 未下载过
  label: 标签
  ebayWarehouse: eBay认证仓
  newlyLaunched: 上架新品
  hotSale: 热销商品
  trialSale: 试卖商品
  promotion: 促销商品
  selectAll: 全选
  addToPrePublishbulk: 批量添加至预刊登列表
  selectStockSort: 请选择库存排序
  selectListingTiomSort: 请选择上架时间排序
  viewProduct: 查看产品
  more: 更多
  collapse: 收起
  page: 页
  search: 搜索
  reset: 重置
  description: 商品详情
  details: 详细介绍
  vehicleModel: 适配车型
  vehicleAttribute: 属性参数
  addToPrePublish: 添加至预刊登列表
  downloadImage: 下载图片集
  downloadAiResource: 下载AI资源包
  inventoryBothFilling: 请完整填写库存范围，两框都要填写。
  minMustBeLessMax: 最小值需小于最大值！
  integerNeeded: 库存范围应当输入正整数
  completelyFillPrice: 请完整填写分销价范围，其中低值需大于0。
  numberIsNeeded: 分销价范围应当输入数字
  goToAddAccount: 请去授权中心添加账号
  youHaveNotConductedSearch: 您还未进行搜索或暂无数据可选择
  youhaveNotSelectCurrently: 您还未进行搜索或暂未选中任何商品
  youhaveNotSelectAny: 您还未选中任何商品
  successfullyAddedToPrePublishInBulk: 批量添加至预刊登列表成功！
  noRelatedProductFound: 没有找到相关商品
  successfullyAddedToPrePublish: 加入预刊登列表成功！
  alreadyPublishedOnAccount: 账号已刊登商品
  notYetPublishedOnAccount: 账号未刊登商品
  failedToPublish: 刊登失败帐号
  successPublishedAccount: 刊登成功帐号
  highToLow: 由高到低
  lowThHigh: 由低到高
  earliest: 最早上架
  latest: 最新上架
  lowInventory: 库存低
skuMappingPage:
  skuMappingManage: SKU映射管理列表
  addSkuMapping: 添加SKU映射
  addSkuMappingBatch: 批量添加SKU映射
  other: 其他
  deleteInBatch: 批量删除
  exoprtAllSkuMapping: 导出所有SKU映射（含库存）
  platformAccount: 平台帐号
  choosePlatformAccount: 请选择平台帐号
  platformSku: 第三方SKU
  enterPlatformSku: 请输入第三方SKU
  createTime: 创建时间
  add: 添加
  added: 添加成功！
  yourSKUMaped: 您上传的SKU映射完毕
  fileParsingFailed: 文件解析失败！
  fileUploadedFailedToParse: 您上传的文件解析失败，未能成功解析的行数和原因如下：
  lineNum: 行数
  skuExist: SKU是否存在
  existent: 存在
  nonExistent: 不存在
  reason: 原因
  none: 暂无
  modifyDataInExecl: 请在execl文件中修改数据，并重新上传
  youUpdatingSKUMappings: 您正在批量更新SKU映射
  downloadTemplate: 下载模板
  filledInAccordingToTemplate: 可按照模板格式填写后再上传
  afterSelectAccount: 选择帐号后才可上传execl表格
  ifDontWantSelect: 如不想选，可选择“其他”
  uploadFileToMapping: 上传文件以导入SKU映射
  supportFileType: 支持文件类型：xlsx
  theFieldDoseNotComplyWithRules: 文件不符合规则，整个表格将不会导入
  clickToUploadDataFile: 点击上传数据文件
  pleaseUploadAFileSmaller: 请上传小于 1MB 的 .xlsx 文件
  batchDeletionHasBeenSubmitted: 批量删除已全部提交，将刷新页面
  youWillDeleteSKUMapping: 您将删除选中的1个或多个SKU映射
  selectMappingAndTry: 请选择1个或多个SKU映射再试试
  youHaventSelectedAnyMapping: 您还没选中任何映射
commodityPage:
  title: 标题
  commodityTitle: 商品标题关键字(模糊)
  productItemCode: 产品 Item Code(精确)
  account: 帐号
  selectAccount: 请选择一个帐号
  platformAIData: 平台AI数据
  eBayDatas: eBay数据
  amazonDatas: Amazon数据
  inventoryRange: 库存区间
  inventorySorting: 库存排序
  selectOneSorting: 请选择一种排序
  lowToHigh: 由低到高
  hightToLow: 由高到低
  prePublishList: 预刊登列表
  AIDataPrePublishListh: AI数据预刊登
  listInPublishing: 刊登中列表
  listSuccessfully: 已刊登列表
  listFailedPublishing: 刊登失败列表
  removeList: 已下架列表
  publishInBatch: 批量刊登
  failedPublishing: 刊登失败
  publishEBayInBatch: 批量eBay数据刊登
  deleteInBatch: 批量删除
  editPriceInBatch: 批量编辑价格
  editInventoryInBatch: 批量编辑库存
  downloadAmazonPack: 下载Amazon数据包
  createAmazonInBatch: 批量生成Amazon AI数据
  publishAmazonInBatch: 批量Amazon 数据刊登
  removeInBatch: 批量下架
  listingInBatch: 批量上架
  itemCode: Item Code/第三方SKU
  image: 图片
  wsBuyingPrice: 批发价
  grSellingPrice: 销售指导价
  guidanceGRPriceLowerThan: 当前销售指导价低于批发价
  availableInventory: 可用库存
  inventoryPrepublishing: 预刊登库存
  publishStatus: 刊登状态
  more: 展开
  operate: 操作
  edit: 编辑
  delete: 删除
  warning: 警告
  goEdit: 去编辑
  dataResource: 数据来源
  thirdPartyInventory: 第三方库存(非实时)
  thirdPartyId: 第三方ID
  remove: 下架
  listing: 上架
  failureReason: 失败原因
  chooseOneAccount: 请选择一个帐号用于刊登选中商品
  eBayAccount: ebay帐号
  amazonAccount: amazon帐号
  chooseAmazonAccount: 请选择一个amazon帐号
  publishInOriginalData: 按原始商品信息刊登
  createEbayAIDataInBatch: 批量生成eBayAI数据
  clickToPublishInBatch: 一键批量刊登
  cancel: 取消
  youWillDelete: 您将删除选中的1个或多个商品
  clickToDelete: 点击确认删除后，操作将不可逆
  confirm: 确认删除
  reviseGRPrice: 批量修改销售指导价
  guidanceGRPrice: 销售指导价
  multiplyBy: 乘以
  add: 加上
  multipleOfWSPrice: 批发价的倍数
  higherThanGRPrice: 高于原销售指导价的金额
  revisePublishInventoryInBatch: 批量修改刊登库存
  publishInventory: 刊登库存
  equalTo: 等于
  quantityPublishedInventory: 刊登库存的数量
  higherThanOriginalPublishedInventory: 高于原刊登库存的数量
  yes: 确认
  youHaventSelected: 您还没选中任何商品
  selectAndTry: 请选择1个或多个商品再试试
  dataBeingProcessed: 您提交的批量刊登数据正在处理中
  refreshCheckInAIPublished: 请稍候在“AI数据预刊登”查看并刷新
  goingToremoveSelectedCommodity: 您将下架选中的1个或多个商品
  clickConfirmToRemoved: 点击确认后，选中商品将下架
  confirmRemoved: 确认下架
  sureToDelete: 确认删除本条记录？
  sureToListing: 确认上架本商品？
  sureToRemoveCommodity: 确认是否下架该商品？
  checkInPublishingLater: 请稍候在“刊登中列表”查看并刷新。
  refresh: 刷新
  selectProductsFromTheSamePlatform: 请选择同一平台的商品来进行批量设置
  selectProductsFromTheSamePlatformPleaseSelect: 全选内容不属于同一平台，请选择同一平台的商品来设置库存
  overEightyCantPublishedPartOne: 当前所选商品标题超过
  overEightyCantPublishedPartTwo: 个字符，不可刊登。请编辑修改商品标题符合规范后再选择。
  commoditiesAreMoreThanEighty: 有以下商品标题超过80个字符,请重新选择商品。
  commoditiesAreMoreThanuTwo: 有以下商品标题超过200个字符,请重新选择商品。
  successfullyDeleted: 删除成功！
  successfullyListed: 上架成功！
  chooseAnAccountAgain: 请选择一个帐号再试试
  haventChooseAnyAccount: 您还没选中任何帐号
  authorizeAnEbayAccount: 请前往“授权中心”启用或授权一个eBay/amazon帐号。
  haventAnyAccount: 您还未授权或启用任何eBay/amazon帐号
  successfullyRemoved: 下架成功！
  successfullyRevisedGRPrice: 修改销售指导价成功！
  failRevised: 修改失败!
  multipleOfWSPriceCannotEmpty: 批发价的倍数不能为空！
  wsBuyingPriceCannotBeLess: 批发价倍数不能小于1,请重新输入
  wsBuyingPriceCannotBeMore: 批发价倍数不能大于10,请重新输入
  wsBuyingPriceMultiple: 批发价倍数异常,请重新输入
  grSellingPriceIncrement: 请填写销售指导价增量
  grSellingPriceIncrementCannotBeLess: 销售指导价增量不能小于0,请重新输入
  abnormalGRSelling: 销售指导价增量异常,请重新输入
  successfullyPublishedInventory: 修改刊登库存成功！
  fillInThePublishInventory: 请填写刊登库存
  qtyCannotBeLessZero: 刊登库存不能小于0,请重新输入
  abnormalInventory: 刊登库存异常,请重新输入
editCommodityPage:
  editCommodityItem: 编辑商品
  uplpadImg: 上传图片
  mainImg: 主图
  setAsMainImg: 设为主图
  deleteImg: 删除本图
  UPCCode: UPC编码
  selectUPCCode: 请选择UPC编码
  selfAdministered: 自行填写
  enterUPC: 填写UPC
  publishCategory: 刊登品类
  itemTitle: 商品标题
  suggestedItemTitle: 建议商品标题
  suggestBetterToModify: 建议修改
  itemNo: 货号
  publishInventory: 刊登库存
  detailsTemplate: 详情模板
  selectDetailsTemplate: 选择详情模板
  blletPoint1: amazon五点描述一
  blletPoint2: amazon五点描述二
  blletPoint3: amazon五点描述三
  blletPoint4: amazon五点描述四
  blletPoint5: amazon五点描述五
  amazonDescription: 亚马逊描述
  saveEdits: 保存编辑
  preview: 预览
  youChosenInputUPC: 您选择了手动填写UPC，请填写UPC
  fillListInventory: 请填写刊登库存！
  chooseAnAccount: 请选择一个刊登帐号
  didntUseTemplate: 未使用模板
  cannotUploadMoreThan12Img: 图片的最大数量不能超过12个,请重新选择！
  uploadedSuccessfully: 全部上传成功！
  youhaveChosen: 您选择了
  items: 个文件，上传成功
  yItems: 个。
  allFailedUploading: 全部上传失败！
  keepAtLeastOneImg: 至少保留一张图！
  contentsSyncSuccessfully: 内容同步成功，正在同步图片
  imagesSyncSuccessfully: 图片同步成功，编辑完成！
  tipsAboutBulltPoints: 亚马逊五点与亚马逊描述应当填写完整，如不需要亚马逊五点，可将亚马逊五点与亚马逊描述全部清空提交
  inputGRSellingPrice: 请输入销售指导价
  grSellingPriceShouldBeNumber: 销售指导价应输入为数字,支持小数
  inputInventoryToPublsh: 请填写刊登库存！
  cannotExceedMaxInventory: 不能超过最大库存
  titleCannotBeAllSpaces: 商品标题不能全为空格！
  titleExceedsMaxLength: 商品标题超长！
  hasUpdatedToEditor: 模板已更新至文本编辑器，请别忘了保存编辑。
inventoryPolicyPage:
  inventoryPolicyInstructions: 库存策略说明
  distributionRetailPlatformPolicy: 分销平台策略
  whenInventoryOfACertainItemOnPlatform: 1、当分销平台的商品库存为X时，平台会向分销用户发送站内信通知对应商品库存已为X件，请各分销者自行调整可售卖库存数。（注 X为真实库存）
  whenInventoryOfACertainItemOnPlatformZero: 2、当分销平台的商品库存为0时，平台会将已进行过绑定映射的分销用户的Ebay商品库存置为”0”
  distributionUserPolicy: 分销用户策略
  distributionUsersCanDecideSettingInventoryAlerting: 1、可自行决定设置分销商品预警库存数，当分销平台的商品达到分销用户设置的库存数时，平台自动将进行过绑定映射的商品在Ebay上设置为0库存。
  longtailProductsInventoryAlertingValue: 2、可单独设置长尾产品的预警库存数，当分销平台的商品达到分销用户设置的长尾商品库存预警值时，平台自动将进行过绑定映射的商品在Ebay上设置为0库存。
  whenInventoryCertainItemOnPlatform: 3、当分销平台的商品库存小于”a”值时，平台会将已进行过绑定映射的分销用户的eBay该商品库存置为”0”
  setOnlineStoresInventoryWithNoGreaterThanB: 4、设置线上门店库存不大于b值，当平台库存大于等于b值时，线上库存为b；当平台库存大于a值小于b值时，线上库存是由平台的库存变化后将平台库存值同步到线上库存值。
  distributionEndInventoryAlertReplenishmentSetting: 分销端库存预警及补货策略设置
  eBayStores: eBay店铺
  amazonStores: amazon店铺
  whenPlatformInventoryLessThan: 当平台库存小于等于
  correspondingProductsInventory: 　时将对应商品平台库存改为 0。
  onlineStoresInventoryNotGreaterThanB: 在线门店库存不大于
  syncPlatformInventoryB: 　　　同步平台库存
  toAmazon: 　% 到amazon
  whenPlatformActualInventoryGreaterThanA: 当平台实际库存大于a,小于b时在线门的库存为实际平台库存。
  enable: 启用
  disable: 禁用
  enabled: 已启用
  disabled: 已禁用
  thisStatusOnlyIndicates: 该状态仅表示“在线库存不大于的策略禁用，库存预警值策略依然有效”
  specialCommodityInventoryAlertReplenishmentSetting: 特殊商品库存预警及补货策略设置
  directionsSpecialCommodityItemsWillApplySpecial: 说明：设置了特殊商品库存预警值后 特殊商品执行特殊商品的预警策略
  editInBatch: 批量编辑
  deleteInBatch: 批量删除
  filterData: 筛选数据
  amazonData: amazon数据
  eBayData: eBay数据
  addSpecialItemAlert: 添加特殊商品预警
  longTailCommodityItemsDistributionInventorySetting: 长尾商品分销库存预警值设置
  noDataForMoment: 暂无数据
  commodityItemName: 商品名称
  itemTitle: 产品标题
  platformInventory: 平台库存量
  inventoryAlertingValue: 库存预警值
  onLineStoreInventory: 在线门店库存不大于
  selectLongTailCommoditiesOfSamePlatform: 请选择同一平台的长尾商品来设置库存
  selectNotFromSamePlatformSelectLongTailPlatform: 全选内容不属于同一平台，请选择同一平台的长尾商品来设置库存
  selectLongTailItemsSetInventoryAlertingValue: 请勾选长尾产品以进行预警值设置
  selectItemsOfTheSamePlatform: 请选择同一平台的商品来设置库存
  canOnlyChooseItemsOfSamePlatform: 只能选择同一平台商品
  deleteInBatchSuccessfully: 批量删除成功
  inputIntegerEqalToOrZero: 请输入正整数值，可以是零，不含小数点、负数
  inputIntegerEqalTo: 请输入正整数值，不可以是零，不含小数点、负数
  amazonInventorySyncPercentage: Amazon 同步平台库存百分比应输入 1-100 之间的正整数值。
  youHaveUnfinishedEditingProcess: 您有正在编辑的项，请先保存或取消正在编辑的行！
  maximumInventoryValueOnlineStore: 最大在线门店库存必须大于平台库存预警设置
  alertingSettingInventoryValueOfPlatform: 平台库存预警设置必须小于最大在线门店库存
  successfullySet: 设置成功！
  partiallySetSuccessfully: 部分设置成功！
  youAreDeleteing: 您正在删除
  selectedCommodities: 项已选中商品库存预警设置
  youAreSetingInventoryAlerts: 您正在为
  selectedItems: 项已选中商品设置库存预警
  onceDeletionWorks: 删除后，将不再对这些库存进行预警。
  haveNoSelectedAnyLongTail: 您还没有选中任何长尾商品
orderManagement:
  allLists: 全部
  pendingPayList: 待付款
  pendingDeliveryList: 待发货
  deliveredList: 已发货
  canceledList: 已取消
  abnormalList: 异常订单
  download: 下载
  orderId: 订单号
  deliveryTrackingNo: 物流单号
  platformOrderNo: 第三方平台单号
  orderCreationTime: 订单创建时间
  exceptionDetails: 异常信息
  start: 开始
  finish: 结束
  dontEnterSpecialCharacters: 请勿输入特殊字符
  orderNumberShouldStartWithDD: 订单号应以DD-开头，已自动修改！
  createNewOrder: 新建订单
  createNewOrdersInBatch: 批量新建订单
  orderNo: 订单编号
  systemOrders: 系统订单(手动)
  ebayOrder: eBay订单
  amazonOrder: Amazon订单
  itemCodeSubject: itemCode/标题
  wholesaleAmount: 批发总额
  actualSalesAmount: 实际售出总额
  createPayDelivered: 创付发时间
  create: 创
  pay: 付
  delivered: 发
  logistics: 物流
  orderStatus: 订单状态
  estimatedGrossProfit: 预估毛利
  reasonsOfCancellation: 取消原因
  goToPay: 去支付
  yourBalanceIs: 您的余额为
  balanceWillBeUsed: 将使用余额支付，确认支付吗？
  orderPaymentHasExceeded: 订单支付已超过
  daysAndCannotBeAppliedAftersalesService: 天，不能申请售后
  orderDataIsIncorrect: 本条订单数据确失
  otherPay: 支付
  yourBalanceIsNotEnough: 您的余额不足以支付当前订单！
  successfullyPaid: 支付成功！
  failedPaid: 支付失败！
  applyForTheAfterSales: 申请售后
  failedDeletion: 删除失败
  youCanUploadAfterFilling: 可按照模板格式填写后再上传
  uploadTemplateFile: 上传模板文件
  fileAnalysisCompletedStartingOrderCreating: 文件分析完毕！正在开始订单创建！
  fileAnalysisFailedFollowHints: 文件解析失败，请按页面提示操作。
  networkAbnormality: 网络异常！
  allOrdersSuccessfullyCreated: 所有订单创建成功！
  allOrdersInTheFileYouUploadedHaveBeenSuccessfullyCreated: 您上传的文件中所有订单均创建成功，您可以继续上传文件或点击左侧目录前往订单列表。
  partialOrdersCreatedSuccessfully: 部分订单创建成功！
  partialOrdersHaveBeenCreatedFailedCreationAreAsFollows: 已成功创建部分订单，未能成功创建的订单如下
  lineNumber: 行号
  externalOrderNo: 外部订单号
  failureCauses: 失败原因
  inventory: 库存
  toCountry: 发往国家
  consigneeFirstName: 收货人名
  consigneeSurname: 收货人姓
  consigneeEmail: 收货人E-mail
  fileAnalysisFailed: 文件解析失败！
  fileYouUploadedFailedAnalysis: 您上传的文件解析失败，未能成功解析的行数和原因如下
  whetherCommodityExists: 商品是否存在
  yes: 存在
  no: 不存在
  filedsNeededModifyingSupplementing: 需修改、补充的字段
  currentlyNo: 暂无
  creatingOrdersInBatch: 正在批量创建订单
  the: 已创建第
  yThOutof: 个订单，总共
  xOrdersBeenCreated: 个订单。
  youAreAddingAnOrder: 您正在新增订单
  skuFromOtherPlatform: 其他平台SKU
  fillInSkuCodeUploadedToOtherPlatform: 请输入上传到其他平台的SKU编码
  skuMapping: SKU映射
  fillInTheCommodityItemCode: 请填写商品Item Code
  orderQuantity: 下单数量
  fillInTheOrderQuantity: 请填写下单数量
  totalAmount: 总金额
  orderType: 订单类型
  selectOrderType: 请选择订单类型
  fillInThePlatformOrderNumber: 请填写第三方平台单号
  consignee: 收件人
  firstName: 名字
  fillInTheFirstName: 请填写名字
  surname: 姓氏
  fillInTheSurname: 请填写姓氏
  phoneNo: 电话号码
  automaticallyAnalyzingPhoneNumber: 自动解析电话号码
  fillInTheConsigneePhoneNumber: 请填写收件人电话号码
  countryCode: 国家编号
  fillInACompleteEmailAddress: 请输入完整的邮箱地址,且不能有空格
  country: 国家
  selectACountry: 请选择国家
  usa: 美国
  postCode: 邮政编码
  fillInThePostCode: 请填写邮政编码
  receivingRegion: 收货地区
  enterStateOrProvince: 请填写州或省
  enterCity: 请填写城市
  fillInAddressOne: 请填写Address1
  fillinAddressTwo: 请填写Address2
  eBayCertifiedWarehouse: Ebay认证仓
  chooseWhetherEbayCertifiedWarehouse: 请选择是否Ebay认证仓
  shi: 是
  fou: 否
  fillInTheEbayItemId: 请填写Ebay ItemID
  fillIntheTransactionId: 请填写TransactionID
  orderNote: 订单备注
  fillInTheOrderNote: 请填写订单备注
  addOrder: 创建订单
  manualOrder: 手动订单
  consigneeSurnameNFirstNameCannotExceedThirtyFive: 收件人姓+收件人名不能超过35个字符
  phoneNumberFormatNeededAnalysisAbnormal: 需要解析的电话号码格式异常！
  numericalPartShouldBeTenDigitsNumber: 数字部分应为10位数字
  onlySpacesNumbersAndAllowed: 只允许输入空格、数字和 -
  inSufficientInventoryFaildToAdd: 库存不足，创建订单失败！
  successfullyAddedOrder: 创建订单成功！
  fillInAPostalCode: 请填写五位或九位邮政编码
  maxInventory: 最大库存为
  ebayItemIdLength: Ebay ItemID长度一般不超过20位
  transactionIdLength: TransactionID 长度一般不超过20位
  itemDoseNotExist: 该商品不存在
  thirtyAndSplitInToAddressTwo: 单行地址不能超过30个字符，您可以拆分至 Address2 填写
  fiftyAndSplitInToAddressOne: 单行地址不能超过50个字符，您可以拆分至 Address1 填写
  enterPlatformSkuFirst: 请先输入第三方SKU
  skuMatchingDoesNoteExist: 对应SKU的产品不存在
  inSufficientInventorySelectedItems: 所选产品库存不足！
