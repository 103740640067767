import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");
const goodsManageRouter = {
  path: "/goodsManage",
  component: Layout,
  redirect: "/goodsManage/manageList",
  meta: {
    icon: "sell",
    title: $t("menus.goodsManage"),
    rank: 1
  },
  children: [
    {
      path: "/goodsManage/manageList",
      name: "GoodsList",
      component: () => import("/@/views/goodsmanagement/goodslist.vue"),
      meta: {
        title: $t("menus.goodsManage"),
        roles:['user'],
        keepAlive: true,
      }
    },
    {
      path: "/goodsManage/skuMappingList",
      name: "SKUMappingList",
      component: () => import("/@/views/goodsmanagement/skumappinglist.vue"),
      meta: {
        title: $t("menus.skuMappingList"),
        roles:['user'],
      }
    },
    {
      path: "/goodsManage/inventoryPolicy",
      name: "InventoryPolicy",
      component: () => import("/@/views/goodsmanagement/inventorypolicy.vue"),
      meta: {
        title: $t("menus.inventoryPolicy"),
        roles:['user'],
      }
    },
    {
      path: "/goodsManage/addSkuMapping",
      name: "AddSKUMapping",
      component: () => import("/@/views/goodsmanagement/addskumapping.vue"),
      meta: {
        title: $t("menus.addSkuMapping"),
        roles: ['user'],
        showLink: false,
      }
    },
    {
      path: "/goodsManage/batchSkuMapping",
      name: "batchSKUMapping",
      component: () => import("/@/views/goodsmanagement/batchskumapping.vue"),
      meta: {
        title: $t("menus.batchSkuMapping"),
        roles: ['user'],
        showLink: false,
      }
    },
    {
      path: "/goodsManage/editGoodsDetails",
      name: "EditGoodsDetails",
      component: () => import("/@/views/goodsmanagement/goodsdetails.vue"),
      meta: {
        title: $t("menus.editGoodsDetails"),
        roles:['user'],
        showLink: false,
      }
    },
    {
      path: "/goodsManage/viewDetails",
      name: "viewDetails",
      component: () => import("/@/views/goodsmanagement/viewdetails.vue"),
      meta: {
        title: $t("menus.viewDetails"),
        roles:['user'],
        showLink: false,
      }
    }
  ]
};

export default goodsManageRouter;
