import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");

const customerService = {
  path: "/customerService",
  component: Layout,
  redirect: "/customerService/manageList",
  hideInMenu:true,
  meta: {
    icon: "service",
    title: $t("menus.customerService"),
    rank: 1
  },
  children: [
    {
      path: "/customerService/manageList",
      name: "customerService",
      component: () => import("/@/views/customerservice/aftersaleslist.vue"),
      meta: {
        title: $t("menus.customerService"),
        roles:['user']
      }
    },
  ]
};

export default customerService;
