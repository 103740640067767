<template>
  <el-config-provider :locale="currentLocale">
    <router-view />
  </el-config-provider>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ElConfigProvider } from "element-plus";
import zhCn from "element-plus/lib/locale/lang/zh-cn";
import en from "element-plus/lib/locale/lang/en";
import BrowserLogger from "alife-logger";

let pid =
  process.env.NODE_ENV == "production"
    ? "jelpuqzvud@b50a0a378ee70c0"
    : "jelpuqzvud@224014b8b0035be";
if (process.env.NODE_ENV == "testing") pid = "jelpuqzvud@3df16fa0b17b8d2";
const __bl = BrowserLogger.singleton({
  pid: pid,
  appType: "web",
  imgUrl: "https://arms-retcode.aliyuncs.com/r.png?",
  enableApiCors: true,
  sendResource: true,
  enableLinkTrace: true,
  behavior: true
});

export default defineComponent({
  name: "app",
  components: {
    [ElConfigProvider.name]: ElConfigProvider
  },
  computed: {
    currentLocale() {
      return this.$storage.locale?.locale === "zh" ? zhCn : en;
    }
  }
});
</script>

<style lang="scss" scoped>
::v-deep .el-scrollbar__bar.is-vertical {
  width: 14px;
}
::v-deep .el-table .el-scrollbar {
  padding-bottom: 17px;
}
::v-deep .el-scrollbar__bar.is-horizontal {
  height: 14px;
}
::v-deep .el-form-item__label {
  font-weight: 700;
}
</style>
