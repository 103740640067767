import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");

const userCenterRouter = {
  path: "/userCenter",
  component: Layout,
  redirect: "/userCenterRouter/userInfo",
  hideInMenu:true,
  meta: {
    icon: "user-single",
    title: $t("menus.userCenter"),
    rank: 1,
  },
  children: [
    {
      path: "/userCenterRouter/userInfo",
      name: "userCenter",
      component: () => import("/@/views/usercenter/usercenter.vue"),
      meta: {
        title: $t("menus.userCenter"),
        roles:['user']
      }
    }
  ]
};

export default userCenterRouter;
