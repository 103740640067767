import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");

const messageCenterRouter = {
  path: "/messageCenter",
  component: Layout,
  redirect: "/messageCenterRouter/messageList",
  hideInMenu: true,
  meta: {
    icon: "bell",
    title: $t("menus.messageCenter"),
    rank: 1
  },
  children: [
    {
      path: "/messageCenterRouter/messageList",
      name: "消息列表",
      component: () => import("/@/views/messagecenter/messagecenter.vue"),
      meta: {
        title: $t("menus.platformNotification"),
        roles: ["user"]
      }
    },
    {
      path: "/messageCenterRouter/businessAlert",
      name: "业务预警",
      component: () => import("/@/views/messagecenter/businessalert.vue"),
      meta: {
        title: $t("menus.businessAlert"),
        roles: ["user"]
      }
    },
    {
      path: "/messageCenterRouter/addNewMessage",
      name: "编辑信息",
      component: () =>
        import("/@/views/messagecenter/components/addNewMessage.vue"),
      meta: {
        title: $t("menus.addMessage"),
        roles: ["admin"]
      }
    },
    {
      path: "/messageCenterRouter/sentMessage",
      name: "已发送消息",
      component: () => import("/@/views/messagecenter/messagecenter.vue"),
      meta: {
        title: $t("menus.sentMessage"),
        roles: ["admin"]
      }
    }
  ]
};

export default messageCenterRouter;
