import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");

const operationAssistantRouter = {
  path: "/operationAssistant",
  component: Layout,
  redirect: "/operationAssistantRouter/fivePointDescription",
  hideInMenu: true,
  meta: {
    icon: "menu",
    title: $t("menus.operationAssistant"),
    rank: 1
  },
  children: [
      {
      path: "/operationAssistantRouter/newGPTPage",
      name: "新版GPT-Beta",
      component: () =>
        import("/@/views/operationAssistant/newGPTPage.vue"),
      meta: {
        title: $t("menus.chatGPT"),
        roles: ["user"]
      }
    },
    // {
    //   path: "/operationAssistantRouter/chatGPTIntelligent",
    //   name: "ChatGPT智能助手",
    //   component: () =>
    //     import("/@/views/operationAssistant/chatGPTIntelligent.vue"),
    //   meta: {
    //     title: $t("menus.chatGPT"),
    //     roles: ["user"]
    //   }
    // },
    {
      path: "/operationAssistantRouter/fivePointDescription",
      name: "五点描述",
      component: () =>
        import("/@/views/operationAssistant/fivePointDescription.vue"),
      meta: {
        title: $t("menus.fivePointDescription"),
        roles: ["user"]
      }
    },
    {
      path: "/operationAssistantRouter/writeTitle",
      name: "撰写标题",
      component: () => import("/@/views/operationAssistant/writeTitle.vue"),
      meta: {
        title: $t("menus.writeTitle"),
        roles: ["user"]
      }
    },
    {
      path: "/operationAssistantRouter/productDescription",
      name: "产品描述",
      component: () =>
        import("/@/views/operationAssistant/productDescription.vue"),
      meta: {
        title: $t("menus.productDescription"),
        roles: ["user"]
      }
    },
    {
      path: "/operationAssistantRouter/invitationComment",
      name: "邀评信生成",
      component: () =>
        import("/@/views/operationAssistant/invitationComment.vue"),
      meta: {
        title: $t("menus.invitationComment"),
        roles: ["user"]
      }
    },
    {
      path: "/operationAssistantRouter/replyCustomers",
      name: "回复客户",
      component: () => import("/@/views/operationAssistant/replyCustomers.vue"),
      meta: {
        title: $t("menus.replyCustomers"),
        roles: ["user"]
      }
    },
    {
      path: "/operationAssistantRouter/warningAndSelling",
      name: "警告跟卖",
      component: () =>
        import("/@/views/operationAssistant/warningAndSelling.vue"),
      meta: {
        title: $t("menus.warningAndSelling"),
        roles: ["user"]
      }
    },
    {
      path: "/operationAssistantRouter/complaintLetter",
      name: "写申诉信",
      component: () =>
        import("/@/views/operationAssistant/complaintLetter.vue"),
      meta: {
        title: $t("menus.complaintLetter"),
        roles: ["user"]
      }
    }
  ]
};

export default operationAssistantRouter;
