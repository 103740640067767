import { http, iPartszoneBaseUrl, jungeBaseUrl } from "../utils/http";
import { java } from "../utils/java";

interface userType extends Promise<any> {
  svg?: string;
  code?: number;
  info?: object;
}

// 获取验证码
export const getVerify = (): userType => {
  return http.request("get", "/captcha");
};
// 登录
export const getLogin = (data: object) => {
  return http.request("post", "/login", { data });
};
// 刷新token
export const refreshToken = (data: object) => {
  return http.request("post", "/refreshToken", { data });
};
// export const searchVague = (data: object) => {
//   return http.request("post", "/searchVague", { data });
// };

//Ipartszone
//登录接口
export const userLogin = (data: object) => {
  return http.request("get", iPartszoneBaseUrl + "/account.php", {
    params: data
  });
};
// export const userLoginOut = (data: object) => {
//   return http.request("get", iPartszoneBaseUrl + "/account.php", {
//     params: data
//   });
// };
// export const testPost = (data: object) => {
//   return http.request(
//     "post",
//     iPartszoneBaseUrl + "/account.php?ctl=Index&met=getConfig&typ=json",
//     { data }
//   );
// };
//注册接口
export const userGetVerifyCode = (data: object) => {
  return http.request("get", iPartszoneBaseUrl + "/account.php", {
    params: data
  });
};
export const usreRegister = (data: object) => {
  return java.request(
    "post",
    jungeBaseUrl + "/distributionAccount/doRegister",
    { data }
  );
};
// 忘记密码
export const forgetPassword = (data: object) => {
  return http.request("get", iPartszoneBaseUrl + "/account.php", {
    params: data
  });
};

//junGe
// 某接口
