import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");

const ordersManageRouter = {
  path: "/ordersManage",
  component: Layout,
  redirect: "/ordersManage/manageList",
  meta: {
    icon: "files",
    title: $t("menus.ordersManage"),
    rank: 1
  },
  children: [
    {
      path: "/ordersManage/manageList",
      name: "ordersManage",
      component: () => import("/@/views/ordersmanagement/orderslist.vue"),
      meta: {
        title: $t("menus.ordersManage"),
        roles: ["user"]
      }
    },
    {
      path: "/ordersManage/addOrder",
      name: "addOrder",
      component: () => import("/@/views/ordersmanagement/addorder.vue"),
      meta: {
        title: $t("menus.addOrder"),
        roles: ["user"]
      }
    },
    {
      path: "/ordersManage/editorder",
      name: "editOrder",
      component: () => import("/@/views/ordersmanagement/editorder.vue"),
      meta: {
        title: $t("menus.editOrder"),
        roles: ["user"],
        showLink: false
      },
    },
    {
      path: "/ordersManage/batchAddOrders",
      name: "batchAddOrders",
      component: () =>
        import("/@/views/ordersmanagement/batchcreateorders.vue"),
      meta: {
        title: $t("menus.batchAddOrders"),
        roles: ["user"]
      }
    }
  ]
};

export default ordersManageRouter;
