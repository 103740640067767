import { h, defineComponent } from "vue";
import { Icon as IconifyIcon, addIcon } from "@iconify/vue/dist/offline";

// element-plus icon
import Check from "@iconify-icons/ep/check";
import HomeFilled from "@iconify-icons/ep/home-filled";
import Setting from "@iconify-icons/ep/setting";
import Lollipop from "@iconify-icons/ep/lollipop";
import RefreshRight from "@iconify-icons/ep/refresh-right";
import ArrowDown from "@iconify-icons/ep/arrow-down-bold";
import ArrowUp from "@iconify-icons/ep/arrow-up-bold";
import CloseBold from "@iconify-icons/ep/close-bold";
import Bell from "@iconify-icons/ep/bell";
import Search from "@iconify-icons/ep/search";
import Picture from "@iconify-icons/ep/picture";
import Star from "@iconify-icons/ep/star";
import Warning from "@iconify-icons/ep/warning";
import CppyDocument from "@iconify-icons/ep/copy-document";

import Box from "@iconify-icons/ep/box";
import Sell from "@iconify-icons/ep/sell";
import Files from "@iconify-icons/ep/files";
import Service from "@iconify-icons/ep/service";
import Unlock from "@iconify-icons/ep/unlock";
import Money from "@iconify-icons/ep/money";
import UserSingle from "@iconify-icons/ep/user";
import ArrowLeft from "@iconify-icons/ep/arrow-left";
import ArrowRight from "@iconify-icons/ep/arrow-right";
import Plus from "@iconify-icons/ep/plus";
import Upload from "@iconify-icons/ep/upload";
import SuccessFilled from "@iconify-icons/ep/success-filled";
import CircleCloseFilled from "@iconify-icons/ep/circle-close-filled";
import WarningFilled from "@iconify-icons/ep/warning-filled";
import Delete from "@iconify-icons/ep/delete";
import Menu from "@iconify-icons/ep/menu";
import VideoPlay from "@iconify-icons/ep/video-play";

addIcon("check", Check);
addIcon("home-filled", HomeFilled);
addIcon("setting", Setting);
addIcon("lollipop", Lollipop);
addIcon("refresh-right", RefreshRight);
addIcon("arrow-down", ArrowDown);
addIcon("arrow-up", ArrowUp);
addIcon("close-bold", CloseBold);
addIcon("bell", Bell);
addIcon("search", Search);
addIcon("picture", Picture);
addIcon("star", Star);
addIcon("warning", Warning);
addIcon("copy-document", CppyDocument);

addIcon("box", Box);
addIcon("sell", Sell);
addIcon("files", Files);
addIcon("service", Service);
addIcon("unlock", Unlock);
addIcon("money", Money);
addIcon("user-single", UserSingle);
addIcon("arrow-left", ArrowLeft);
addIcon("arrow-right", ArrowRight);
addIcon("plus", Plus);
addIcon("upload", Upload);
addIcon("success-filled", SuccessFilled);
addIcon("circle-close-filled", CircleCloseFilled);
addIcon("warning-filled", WarningFilled);
addIcon("delete", Delete);
addIcon("menu", Menu);
addIcon("video-play", VideoPlay);

// remixicon
import ArrowRightSLine from "@iconify-icons/ri/arrow-right-s-line";
import ArrowLeftSLine from "@iconify-icons/ri/arrow-left-s-line";
import LogoutCircleRLine from "@iconify-icons/ri/logout-circle-r-line";
import InformationLine from "@iconify-icons/ri/information-line";
import ArrowUpLine from "@iconify-icons/ri/arrow-up-line";
import ArrowDownLine from "@iconify-icons/ri/arrow-down-line";
import Bookmark2Line from "@iconify-icons/ri/bookmark-2-line";
import User from "@iconify-icons/ri/user-3-fill";
import Lock from "@iconify-icons/ri/lock-fill";

import MenuUnfold from "@iconify-icons/ri/menu-unfold-fill";
import MenuFold from "@iconify-icons/ri/menu-fold-fill";
import FileList from "@iconify-icons/ri/file-word-2-line";
import VideoList from "@iconify-icons/ri/vidicon-line";
addIcon("arrow-right-s-line", ArrowRightSLine);
addIcon("arrow-left-s-line", ArrowLeftSLine);
addIcon("logout-circle-r-line", LogoutCircleRLine);
addIcon("information-line", InformationLine);
addIcon("arrow-up-line", ArrowUpLine);
addIcon("arrow-down-line", ArrowDownLine);
addIcon("bookmark-2-line", Bookmark2Line);
addIcon("user", User);
addIcon("lock", Lock);
addIcon("menu-unfold", MenuUnfold);
addIcon("menu-fold", MenuFold);
addIcon("file-list", FileList);
addIcon("video-list", VideoList);
// Iconify Icon在Vue里离线使用（用于内网环境）https://docs.iconify.design/icon-components/vue/offline.html
export default defineComponent({
  name: "IconifyIconOffline",
  components: { IconifyIcon },
  props: {
    icon: {
      type: String,
      default: ""
    }
  },
  render() {
    const attrs = this.$attrs;
    return h(
      IconifyIcon,
      {
        icon: `${this.icon}`,
        style: attrs?.style
          ? Object.assign(attrs.style, { outline: "none" })
          : { outline: "none" },
        ...attrs
      },
      {
        default: () => []
      }
    );
  }
});
