import Axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import {
  resultType,
  PureHttpError,
  RequestMethods,
  PureHttpResponse,
  PureHttpRequestConfig
} from "./types.d";
import qs from "qs";
import NProgress from "../progress";
// import { loadEnv } from "@build/index";
import { getToken } from "/@/utils/auth";

import { getSingeCookie } from "/@/utils/auth";
import { useRouter } from "vue-router";

import { useUserStoreHook } from "/@/store/modules/user";
import { ElMessage, ElMessageBox } from "element-plus";
import type { Action } from "element-plus";
import { h } from "vue";
import Cookies from "js-cookie";

const router = useRouter();
const goLogin = () => {
  router.push({ path: "/login" });
};

const currentVersion = 1.01;
let foundNewVersion = false;
// 加载环境变量 VITE_PROXY_DOMAIN（开发环境）  VITE_PROXY_DOMAIN_REAL（打包后的线上环境）
// const { VITE_PROXY_DOMAIN, VITE_PROXY_DOMAIN_REAL } = loadEnv();

// 相关配置请参考：www.axios-js.com/zh-cn/docs/#axios-request-config-1
const defaultConfig: AxiosRequestConfig = {
  // baseURL:
  //   process.env.NODE_ENV === "production"
  //     ? VITE_PROXY_DOMAIN_REAL
  //     : VITE_PROXY_DOMAIN,
  // 当前使用mock模拟请求，将baseURL制空，如果你的环境用到了http请求，请删除下面的baseURL启用上面的baseURL，并将11行、16行代码注释取消
  baseURL: "",
  timeout: 1200000,
  headers: {
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/json; application/octet-stream"
  },
  // 数组格式参数序列化
  paramsSerializer: params => qs.stringify(params, { indices: false })
};

class PureHttp {
  constructor() {
    this.httpInterceptorsRequest();
    this.httpInterceptorsResponse();
  }
  // 初始化配置对象
  private static initConfig: PureHttpRequestConfig = {};

  // 保存当前Axios实例对象
  private static axiosInstance: AxiosInstance = Axios.create(defaultConfig);

  // 请求拦截
  private httpInterceptorsRequest(): void {
    PureHttp.axiosInstance.interceptors.request.use(
      (config: PureHttpRequestConfig) => {
        if (foundNewVersion) return;
        const $config = config;
        // 开启进度条动画
        NProgress.start();
        // 优先判断post/get等方法是否传入回掉，否则执行初始化设置等回掉
        if (typeof config.beforeRequestCallback === "function") {
          config.beforeRequestCallback($config);
          return $config;
        }
        if (PureHttp.initConfig.beforeRequestCallback) {
          PureHttp.initConfig.beforeRequestCallback($config);
          return $config;
        }
        config.headers["user_level_id"] = getSingeCookie("user_level_id");

        if (
          config.url.indexOf("addEabyAuth") != -1 ||
          config.url.indexOf("updateEabyAuth") != -1
        ) {
          return $config;
        }
        config.headers["perm_id"] = getSingeCookie("uid");
        config.headers["perm_key"] = getSingeCookie("ukey");
        return $config;

        const token = getToken();
        if (token) {
          const data = JSON.parse(token);
          const now = new Date().getTime();
          const expired = parseInt(data.expires) - now <= 0;
          if (expired) {
            // token过期刷新
            useUserStoreHook()
              .refreshToken(data)
              .then((res: resultType) => {
                config.headers["perm_id"] = getSingeCookie("uid");
                config.headers["perm_key"] = getSingeCookie("perm_key");
                return $config;
              });
          } else {
            config.headers["Authorization"] = "Bearer " + data.accessToken;
            return $config;
          }
        } else {
          return $config;
        }
      },
      error => {
        return Promise.reject(error);
      }
    );
  }

  // 响应拦截
  private httpInterceptorsResponse(): void {
    const instance = PureHttp.axiosInstance;
    instance.interceptors.response.use(
      (response: PureHttpResponse) => {
        const $config = response.config;
        // 关闭进度条动画
        NProgress.done();

        let reloadNum = Cookies.get("reloadNum") ? Cookies.get("reloadNum") : 0;
        // console.log(typeof(response),'response')
        //  && Number(response.headers["Front-Version"]) != currentVersion
        if (
          response.headers["front-version"] &&
          !foundNewVersion &&
          Number(response.headers["front-version"]) != currentVersion
        ) {
          if (Number(reloadNum) >= 2) {
          } else {
            reloadNum = Number(reloadNum) + 1;
            Cookies.set("reloadNum", String(reloadNum), {
              expires: new Date(new Date() * 1 + 32400 * 1000)
            });

            foundNewVersion = true;
            location.reload(true);
          }

          // console.log(response.headers)
          // ElMessageBox.confirm('点击确认将加载新版本', '检测到新版本', {
          //   // if you want to disable its autofocus
          //   // autofocus: false,
          //   cancelButtonText: '暂不更新',
          //   confirmButtonText: '确认',
          //  }).then(() => {
          //     location.reload(true)
          //  }).catch(() => {
          //     foundNewVersion = false;
          //  })
        } else {
          foundNewVersion = false;
        }

        if (response.headers["content-disposition"]) {
          response.data = {
            data: response.data,
            filename: response.headers["content-disposition"]
          };
        }

        if (typeof $config.beforeResponseCallback === "function") {
          // 优先判断post/get等方法是否传入回掉，否则执行初始化设置等回掉
          $config.beforeResponseCallback(response);
          return response.data;
        }
        if (PureHttp.initConfig.beforeResponseCallback) {
          PureHttp.initConfig.beforeResponseCallback(response);
          return response.data;
        }
        if (typeof response.data == "string") {
          try {
            const newData = eval("(" + response.data + ")"); //这个可以将字符串转成json对象来使用
            return newData;
          } catch {
            return response.data;
          }
        }
        return response.data;
      },
      (error: PureHttpError) => {
        const $error = error;
        // let errWord = "发送请求时发生未知错误，请检查url或CORS配置。";
        // switch (error.response.status) {
        //   case 500:
        //     errWord = "服务器出现未知错误，statusCode:500";
        //     break;
        //   case 502:
        //     errWord = "服务器出现未知错误，statusCode:502";
        //     break;
        //   case 504:
        //     errWord = "服务器网关超时，statusCode:504";
        //     break;
        //   case 404:
        //     errWord = "未找到网址，statusCode:404";
        //     break;
        //   case 403:
        //     errWord = "该请求未被授权，statusCode:403";
        //     break;
        //   case 401:
        //     errWord = "您没有访问权限，statusCode:401";
        //     break;
        //   default:
        //     break;
        // }

        let errWord = "";
        if (
          error.response.data?.errorCode &&
          error.response.data?.errorMessage
        ) {
          errWord = error.response.data?.errorMessage;
        } else if (error.response.data?.errorMessage) {
          errWord = error.response.data?.errorMessage;
        } else if (error.response.data?.errorCode) {
          errWord = error.response.data?.errorCode;
        } else if (error.response.status == 0) {
          errWord = "网络异常，请检查网络设置。";
        } else if (error.response.status == 401) {
          errWord = "访问权限过期，请退出重新登录";
        } else {
          errWord = JSON.stringify(error.response);
        }

        // console.log('heiheihei',JSON.parse(
        //   String.fromCharCode.apply(null, new Uint8Array(error.response.data))
        // ))

        ElMessage({
          message: h("div", null, [
            h("p", null, "出错啦！状态码 " + error.response.status),
            h("p", null, errWord)
          ]),
          showClose: false,
          type: "error"
        });

        $error.isCancelRequest = Axios.isCancel($error);
        // 关闭进度条动画
        NProgress.done();
        if (error.response.status == 401) {
          goLogin();
        }

        // 所有的响应异常 区分来源为取消请求/非取消请求
        return Promise.reject($error);
      }
    );
  }

  // 通用请求工具函数
  public request<T>(
    method: RequestMethods,
    url: string,
    param?: AxiosRequestConfig,
    axiosConfig?: PureHttpRequestConfig
  ): Promise<T> {
    const config = {
      method,
      url,
      ...param,
      ...axiosConfig
    } as PureHttpRequestConfig;

    // 单独处理自定义请求/响应回掉
    return new Promise((resolve, reject) => {
      PureHttp.axiosInstance
        .request(config)
        .then((response: undefined) => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  // 单独抽离的post工具函数
  public post<T, P>(
    url: string,
    params?: T,
    config?: PureHttpRequestConfig
  ): Promise<P> {
    return this.request<P>("post", url, params, config);
  }

  // 单独抽离的get工具函数
  public get<T, P>(
    url: string,
    params?: T,
    config?: PureHttpRequestConfig
  ): Promise<P> {
    return this.request<P>("get", url, params, config);
  }
}
export const java = new PureHttp();
