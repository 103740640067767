buttons:
  hsLoginOut: LoginOut
  hsfullscreen: FullScreen
  hsexitfullscreen: ExitFullscreen
  hsrefreshRoute: RefreshRoute
  hslogin: Login
  hsregist: Register
  hsadd: Add
  hsmark: Mark/Cancel
  hssave: Save
  hssearch: Search
  hsexpendAll: Expand All
  hscollapseAll: Collapse All
  hssystemSet: Open ProjectConfig
  hsdelete: Delete
  hsclose: Close
  hsreload: Reload
  hscloseCurrentTab: Close CurrentTab
  hscloseLeftTabs: Close LeftTabs
  hscloseRightTabs: Close RightTabs
  hscloseOtherTabs: Close OtherTabs
  hscloseAllTabs: Close AllTabs
menus:
  hshome: Home
  resultEbaySuccess: eBayResult
  resultAmazonSuccess: Amazon Auth Result
  templatePreview: 模板预览页
  noviceGuide: 入门指南
  processIntroduction: Process Introduction
  guidanceVideo: Guidance Video
  categoryList: Category&Search
  selectGoods: Selection Page
  welcome: Select Goods
  welcomeNew: Select Goods(New)
  goodsManage: Commodity Management
  batchSkuMapping: batch Add SKU Mapping
  skuMappingList: SKU Mapping List
  inventoryPolicy: INVENTORY POLICY SETTINGS
  addSkuMapping: Add SKU Mapping
  addOrder: Add Order
  editOrder: Edit Order
  batchAddOrders: Batch-Adding Orders
  goodsList: Goods List
  editGoodsDetails: Edit Goods Details
  viewDetails: view Goods Details
  ordersManage: Orders Management
  customerService: Customer Service
  authorizationCenter: Authorization Center
  payoneerRecharge: Payoneer Recharge
  assetsManage: Assets Management
  rechargeInfo: Recharge Ways
  userCenter: User Center
  operationAssistant: ChatGPT AI
  chatGPT: ChatGPT Intelligent Assistant
  fivePointDescription: Five Point Description
  writeTitle: Write A Title
  productDescription: Product Description
  invitationComment: Invitation For Comment
  replyCustomers: Reply To Customers
  warningAndSelling: Warning And Selling
  complaintLetter: Write A Complaint Letter
  messageCenter: Message Center
  messageList: Message List
  platformNotification: Platform Notification
  businessAlert: Business Alert
  sentdMessage: Sent Messages
  addMessage: Add a new Message
  hslogin: Login
  hsregist: Register
  hserror: Error Page
  hsfourZeroFour: "404"
  hsfourZeroOne: "403"
  hsFive: "500"
  permission: Permission Management
  permissionPage: Page Permission
  permissionButton: Button Permission
status:
  hsLoad: Loading...
loginPage:
  startGuide: Getting Started Guide
  customerService: Customer Service
  welcomeToLogin: Login
  email: Email
  password: Password
  login: Login
  forgotPassword: Forget password
  registerNow: Create Account
  copyRight: All Rights Reserved
  distributionManagement: Distribution & Retail Management Centre
  loginSucceeded: Successfully Login
  loginWarning: You are not a distributor yet, please contact our distribution manager.
registPage:
  obtainVerificationCode: Get a verification code
  individualRegistration: Register as individual
  corporateRegistration: Register as enterprise
  verificationCodeSent: Verification code sent
  pleaseLogInToEmailViewCode: Please log in your email to check the verification code later.
  codeHasBeenSentToEmail: Verification code is already sent into your email：
  ok: OK
  secondsResend: Seconds Resend
  resentVerificationCode: Resend Verification Code
  loginSuccessful: Registered!
  inputYourName: Please enter First Name.
  checkFirstNameWordsNumber: The name should have no more than 6 Chinese Characters or no more than 12 English letters.
  inputYourLastName: Please enter Family Name.
  checkLastNameWordsNumber: The name should have no more than 6 Chinese Characters or no more than 12 English letters.
  inputYourPhoneNumber: Please enter the phone number.
  inputCorrecPhoneNumber: Please enter correct phone number.
  setPassword: Please set a login password.
  cantSetPasswordToSpecialCharacters: Password cannot be pure special characters.
  checkPassword: The password should consist of 6-18 alphabet letters, numbers or special characters.
  inputConfirmationPassword: Please enter correct password.
  twoPasswordsNotMatch: The passwords twice entered are not matched.
  inputEmailVerificationCode: Please enter the email verification code.
  checkEmailVerificationCode: The email verification code consists of at least four numbers.
  checkBusinessLicense: You have not uploaded the business license.
  uploadSuccessful: Successfully Uploaded!
  uploadFailed: Failed Uploaded!
  inputEmailAddress: Please enter a complete email address.
  incorrectEmailFormat: Incorrect email format.
  contactName: Contact Person
  firstName: First Name
  inputFirstName: Please enter first name.
  lastName: Family name
  inputLastName: Please enter Family Name.
  inputEmail: Please enter email address.
  verifyCode: Verification Code
  password: Password
  inputLoginPassword: Please enter password
  confirmPassword: Verify password
  inputLoginPasswordAgain: Please enter password again
  phoneNumber: Phone Number
  companyName: Company Name
  inputCompanyName: Please enter company name
  companyAddress: Company Address
  inputCompanyAddress: Please enter company address
  companyBusinessLicense: Certificate of Business
  register: Sign in
  hasAccountGoLogin: Already have an account, go to login
  scanningQRCodeOnWeChat: Scanning QR codes on WeChat allows you to monitor the progress of the audit.
forgetPage:
  submit: Submit
  goLogin: Go to Login
selectionPage:
  RetailingNSelection: Retailing & Selection
  keyword: KEY WORD
  productOrItemCode: Product (Vague Search)/Item Code
  accountToPublish: Account
  chooseAccountToPublish: Please choose your account to publish
  category: Category
  broadCategory: BroadCategory
  auto: Auto
  home: Home
  allCategorys: All
  chooseOneCategory: Please choose one category
  chooseOneParentCategory: Please select a parent category first
  hotCategory: Popular categories
  inventory: Inventory
  minQty: Min Qty
  maxQty: Max Qty
  retailingPrice: Price
  lowestPrice: Lowest Price
  highestPrice: Highest Price
  productDownload: Information
  chooseDownloadedOrNot: Choose whether it’s been downloaded or not
  downloaded: Downloaded
  notDownLoaded: Not-Yet downloaded
  label: Label
  ebayWarehouse: eBay Certified Warehouse
  newlyLaunched: Newly Launched
  hotSale: Hot Sale
  trialSale: Trial Sale
  promotion: Promotion
  selectAll: Select All
  addToPrePublishbulk: Add to Pre-Publishing List in bulk
  selectStockSort: Select a stock sort
  selectListingTiomSort: Select a listing time sort
  viewProduct: View Product
  more: More
  collapse: Collapse
  page: page
  search: Search
  reset: Reset
  description: Product Description
  details: Details
  vehicleModel: Adaptive Vehicle Model
  vehicleAttribute: Attributes
  addToPrePublish: Add to Pre-Publishing List
  downloadImage: Download Images
  downloadAiResource: Download AI Resource
  inventoryBothFilling: Please completely fill in the inventory range, both needed filling
  minMustBeLessMax: Min value needs to be less than Max value!
  integerNeeded: A positive integer is needed for inventory range
  completelyFillPrice: Please completely fill in the price range, between which min value must be greater than 0.
  numberIsNeeded: Number is needed entering in Price range
  goToAddAccount: Please go to the Authorization Center to add an account
  youHaveNotConductedSearch: You have not conducted a search or no data to select currently
  youhaveNotSelectCurrently: You have not conducted a search or you have not selected any product/commodity
  youhaveNotSelectAny: You haven’t selected any product
  successfullyAddedToPrePublishInBulk: Successfully added to Pre-Publishing list in bulk!
  noRelatedProductFound: No related product found
  successfullyAddedToPrePublish: Successfully Added to Pre-Publishing List!
  alreadyPublishedOnAccount: Already published on account
  notYetPublishedOnAccount: Not-Yet Published on account
  failedToPublish: Account Failed to publish
  successPublishedAccount: Published account
  highToLow: High to Low
  lowThHigh: Low to High
  earliest: Earliest
  latest: Latest
  lowInventory: Low inventory
skuMappingPage:
  skuMappingManage: SKU mapping Management List
  addSkuMapping: ADD SKU MAPPING
  addSkuMappingBatch: ADD SKU MAPPING in Batch
  other: Other
  deleteInBatch: Delete in Batch
  exoprtAllSkuMapping: Export all SKU mapping (incl. inventory)
  platformAccount: Platform Account
  choosePlatformAccount: Please choose a platform account
  platformSku: Platform SKU
  enterPlatformSku: Please enter third party SKU
  createTime: Creation Time
  add: ADD
  added: Successfully added!
  yourSKUMaped: The SKU you uploaded has been mapped.
  fileParsingFailed: File parsing failed.
  fileUploadedFailedToParse: The file you uploaded failed to parse. The number of lines and reasons for the failure are as follows
  lineNum: Line Number
  skuExist: Does SKU exist
  existent: Existent
  nonExistent: Non-existent
  reason: Reason
  none: None
  modifyDataInExecl: Please modify the data in the execl file and upload it again.
  youUpdatingSKUMappings: You are updating SKU mappings in bulk
  downloadTemplate: Download the template
  filledInAccordingToTemplate: Can be filled in according to the template format before uploading
  afterSelectAccount: Only after selecting an account can the execl table be uploaded.
  ifDontWantSelect: If you do not want to select it, you can choose 'Other'.
  uploadFileToMapping: Upload file to import SKU mapping
  supportFileType: Supported file types：xlsx
  theFieldDoseNotComplyWithRules: The field does not comply with the rules, and the entire table will not be imported
  clickToUploadDataFile: Click to upload data file
  pleaseUploadAFileSmaller: Please upload a. xlsx file smaller than 1MB.
  batchDeletionHasBeenSubmitted: Batch deletion has been fully submitted, page will be refreshed
  youWillDeleteSKUMapping: You are going to delete one or more selected SKU mapping.
  selectMappingAndTry: Please select one or more mapping for another try.
  youHaventSelectedAnyMapping: You haven’t selected any mapping
commodityPage:
  title: Title
  commodityTitle: Commodity title key words
  productItemCode: Product item code
  account: Account
  selectAccount: Please select the account
  platformAIData: Platform
  eBayDatas: eBay datas
  amazonDatas: Amazon datas
  inventoryRange: INV Range
  inventorySorting: INV Sorting
  selectOneSorting: Please select one kind of sorting
  lowToHigh: Low to High
  hightToLow: High to Low
  prePublishList: Pre-Publishing List
  AIDataPrePublishListh: AI-Data Pre-Publishing List
  listInPublishing: List in Publishing
  listSuccessfully: List Successfully Published
  listFailedPublishing: List Failed Publishing
  removeList: Removed List
  publishInBatch: Publish in Batch
  failedPublishing: Failed Publishing
  publishEBayInBatch: Publish e-Bay Data in Batch
  publishAmazonInBatch: Publish Amazon Data in Batch (Into AI data)
  deleteInBatch: Delete in Batch
  editPriceInBatch: Edit Price in Batch
  editInventoryInBatch: Edit Inventory in Batch
  downloadAmazonPack: Download Amazon Data Pack
  createAmazonInBatch: Create Amazon AI data in Batch
  removeInBatch: Remove in Batch
  listingInBatch: Listing in Batch
  itemCode: Item Code
  image: Image
  wsBuyingPrice: WS Buying Price
  grSellingPrice: GR Selling Price
  guidanceGRPriceLowerThan: GR Selling Price is Lower than WS Buying Price
  availableInventory: Available Inventory
  inventoryPrepublishing: Inventory Prepublishing
  publishStatus: Publish Status
  more: More
  operate: Operate
  edit: Edit
  delete: Delete
  warning: Warning
  goEdit: Go to Edit
  dataResource: Data Resource
  thirdPartyInventory: Third Party Inventory
  thirdPartyId: Third Party ID
  remove: Remove
  listing: Listing
  failureReason: Failure Reason
  chooseOneAccount: Please choose one account to publish the selected commodities
  eBayAccount: eBay Account
  amazonAccount: Amazon Account
  chooseAmazonAccount: Please choose an Amazon Account
  publishInOriginalData: Publish in Original Commodity Data
  createEbayAIDataInBatch: Create eBay AI data in Batch
  clickToPublishInBatch: Click to publish in Batch
  cancel: CANCEL
  youWillDelete: You are going to delete one or more selected commodity
  clickToDelete: Click confirm, delete is irreversible.
  confirm: Confirm
  reviseGRPrice: Revise GR Selling Price in Batch
  guidanceGRPrice: GR Selling Price
  multiplyBy: Multiply by
  add: Add
  multipleOfWSPrice: Multiple of WS Buying Price
  higherThanGRPrice: Amount higher than original GR Selling price
  revisePublishInventoryInBatch: Revise Publish Inventory in Batch
  publishInventory: Publish Inventory
  equalTo: Equal to
  quantityPublishedInventory: Quantity of published inventory
  higherThanOriginalPublishedInventory: Higher than original published inventory
  yes: Confirm
  youHaventSelected: You haven’t selected any commodity
  selectAndTry: Please select one or more commodity for another try
  dataBeingProcessed: The data you submitted to publish in batch is being processed
  refreshCheckInAIPublished: Please refresh and check in ‘AI-data Re-Publish List’ later.
  goingToremoveSelectedCommodity: You are going to remove the selected one or more commodity.
  clickConfirmToRemoved: Click confirm, the selected commodity will be removed from the List.
  confirmRemoved: Confirm
  sureToDelete: Are you sure to delete this record?
  sureToListing: Are you sure to listing this record?
  sureToRemoveCommodity: Are you sure to remove this commodity?
  checkInPublishingLater: Please refresh and check in List in Publishing later.
  refresh: Refresh
  selectProductsFromTheSamePlatform: Please select products from the same platform for batch settings.
  selectProductsFromTheSamePlatformPleaseSelect: All selected content does not belong to the same platform. Please select products from the same platform to set inventory
  overEightyCantPublishedPartOne: Current commodity’s title selected is over
  overEightyCantPublishedPartTwo: characters and cannot be published. Please select again after revising the title meeting standard specification.
  commoditiesAreMoreThanEighty: Below commodities’ titles are more than 80 characters, please select the commodity again.
  commoditiesAreMoreThanuTwo: Below commodities’ titles are more than 200 characters, please select the commodity again.
  successfullyDeleted: Successfully Deleted!
  successfullyListed: Successfully Listed!
  chooseAnAccountAgain: Please choose an account to try again.
  haventChooseAnyAccount: You haven’t chosen any account.
  authorizeAnEbayAccount: Please go to the Authorization Center to enable or authorize an eBay/Amazon account.
  haventAnyAccount: You haven’t authorized or enabled any eBay/Amazon account.
  successfullyRemoved: Successfully Removed!
  successfullyRevisedGRPrice: Successfully revised GR Selling Price!
  failRevised: Fail Revised!
  multipleOfWSPriceCannotEmpty: Multiple of WS Buying Price cannot be empty.
  wsBuyingPriceCannotBeLess: WS Buying Price multiple cannot be less than 1, please re-enter.
  wsBuyingPriceCannotBeMore: WS Buying Price multiple cannot be more than 10, please re-enter.
  wsBuyingPriceMultiple: Abnormal WS Buying Price multiple, please re-enter.
  grSellingPriceIncrement: Please fill in GR Selling Price increment.
  grSellingPriceIncrementCannotBeLess: GR Selling Price increment cannot be less than 0, please re-enter.
  abnormalGRSelling: Abnormal GR Selling Price increment, please re-enter.
  successfullyPublishedInventory: Successfully published inventory!
  fillInThePublishInventory: Please fill in the publish inventory.
  qtyCannotBeLessZero: Inventory QTY cannot be less than 0, please re-enter.
  abnormalInventory: Abnormal inventory, please re-enter.
editCommodityPage:
  editCommodityItem: Edit Commodity Item
  uplpadImg: Upload Image
  mainImg: Main Image
  setAsMainImg: Set as Main Image
  deleteImg: Delete Image
  UPCCode: UPC Code
  selectUPCCode: Select UPC Code
  selfAdministered: Self-administered
  enterUPC: Enter UPC
  publishCategory: Publish Category
  itemTitle: Item Title
  suggestedItemTitle: Suggested item title
  suggestBetterToModify: Suggest better to Modify
  itemNo: Item No.
  publishInventory: Publish Inventory
  detailsTemplate: Details Template
  selectDetailsTemplate: Select Details Template
  blletPoint1: Amazon Product Bullet Point 1
  blletPoint2: Amazon Product Bullet Point 2
  blletPoint3: Amazon Product Bullet Point 3
  blletPoint4: Amazon Product Bullet Point 4
  blletPoint5: Amazon Product Bullet Point 5
  amazonDescription: Amazon Product Description
  saveEdits: Save Edits
  preview: Preview
  youChosenInputUPC: You have chosen to input UPC manually, please fill it here
  fillListInventory: Please fill the List Inventory
  chooseAnAccount: Please choose an Account to pre-publish
  didntUseTemplate: Didn’t use template
  cannotUploadMoreThan12Img: Cannot upload more than 12 images, please choose again.
  uploadedSuccessfully: Uploaded Successfully!
  youhaveChosen: You have chosen
  items: items,
  yItems: items uploaded successfully.
  allFailedUploading: All failed uploading!
  keepAtLeastOneImg: Keep at least ONE image.
  contentsSyncSuccessfully: Contents sync successfully, syncing images now.
  imagesSyncSuccessfully: Images sync successfully, you have finished editing!
  tipsAboutBulltPoints: All five Amazon Key Descriptions and Amazon Bullet Points should be completely filled. If you don’t need five Amazon Key Descriptions, clear all five Amazon Key Descriptions and Amazon Bullet Points to submit
  inputGRSellingPrice: Please input GR Selling Price
  grSellingPriceShouldBeNumber: GR Selling Price should be a number, decimals supported.
  inputInventoryToPublsh: Please input Inventory to publish!
  cannotExceedMaxInventory: Cannot exceed max inventory!
  titleCannotBeAllSpaces: Item Title cannot be all spaces!
  titleExceedsMaxLength: Item Title exceeds max length!
  hasUpdatedToEditor: Template has updated to the text editor, please don’t forget to save your editing.
inventoryPolicyPage:
  inventoryPolicyInstructions: Inventory Policy Instructions
  distributionRetailPlatformPolicy: Distribution&Retail Platform Policy
  whenInventoryOfACertainItemOnPlatform: 1. When the inventory of a certain item on the platform is X, the platform will send an internal letter to retailers notifying the certain item inventory is X and retailers can modify available inventory for sale. (Note, X refers to the actual inventory value)
  whenInventoryOfACertainItemOnPlatformZero: 2. When the inventory of a certain item on the platform is 0, the platform will set the eBay item of the retailers who have undergone binding mapping as 0 inventory.
  distributionUserPolicy: Distribution user policy
  distributionUsersCanDecideSettingInventoryAlerting: 1.Distribution users can decide setting inventory alerting number of distribution commodities. When a certain commodity item on the platform reach the inventory value that distribution retailers have set, the platform will automatically set the certain commodity on eBay that has undergone binding mapping as 0 inventory.
  longtailProductsInventoryAlertingValue: 2.The long tail products’ inventory alerting value can be set separately. When a certain commodity item of the platform reach to the inventory that distribution retailers have set for long-tail products, the platform will automatically set the certain commodity on eBay that has undergone binding mapping as 0 inventory.
  whenInventoryCertainItemOnPlatform: 3.When the inventory of a certain item on the platform is smaller than ”a”, the platform will set the certain item on eBay that has undergone binding mapping as 0 inventory.
  setOnlineStoresInventoryWithNoGreaterThanB: 4.Set online store’s inventory with no greater than b, When the platform inventory is greater than or equal to b, online store inventory turns out as b; When platform’s inventory is greater than a and smaller than b, online store’s inventory is synced with platform’s inventory after platform inventory has changed
  distributionEndInventoryAlertReplenishmentSetting: Distribution end Inventory alert and replenishment setting Policy
  eBayStores: eBay Stores
  amazonStores: Amazon Stores
  whenPlatformInventoryLessThan: When the platform inventory is less than or equal to
  correspondingProductsInventory: , the corresponding product’s platform inventory will be set to 0
  onlineStoresInventoryNotGreaterThanB: Online stores’ inventory is not greater than
  syncPlatformInventoryB: Sync platform inventory
  toAmazon: % to Amazon.
  whenPlatformActualInventoryGreaterThanA: When platform actual inventory is greater than a and less than b, online inventory is platform  actual inventory.
  enable: Enable
  disable: Disable
  enabled: Enabled
  disabled: Disabled
  thisStatusOnlyIndicates: This status only indicates that the policy of online inventory not exceeding is disabled, and the inventory warning value policy is still valid
  specialCommodityInventoryAlertReplenishmentSetting: Special Commodity Inventory alert and replenishment setting Policy
  directionsSpecialCommodityItemsWillApplySpecial: Directions： Special commodity Items will apply special item inventory alert policy after their inventory alert value are set.
  editInBatch: Edit in batch
  deleteInBatch: Delete in batch
  filterData: Filter data
  amazonData: Amazon data
  eBayData: eBay data
  addSpecialItemAlert: Add Special item alert
  longTailCommodityItemsDistributionInventorySetting: Long-tail commodity items distribution inventory alert value setting
  noDataForMoment: No data for the moment
  commodityItemName: Commodity Item Name
  itemTitle: Item Title
  platformInventory: Platform Inventory
  inventoryAlertingValue: Inventory alerting Value
  onLineStoreInventory: Online Store Inventory No More Than
  selectLongTailCommoditiesOfSamePlatform: Select long-tail commodities of the same platform to set inventory
  selectNotFromSamePlatformSelectLongTailPlatform: The selected contents are not from the same platform, please select long-tail commodities of the same platform to set inventory
  selectLongTailItemsSetInventoryAlertingValue: Select Long-tail Items to set Inventory alerting Value
  selectItemsOfTheSamePlatform: Select items of the same platform to set inventory
  canOnlyChooseItemsOfSamePlatform: Can only choose items of the same platform
  deleteInBatchSuccessfully: Delete in batch successfully
  inputIntegerEqalToOrZero: Please input an integer equal to or greater than 0. No decimals or negative integers are allowed
  inputIntegerEqalTo: Please input an integer greater than 0. No 0, decimals or negative integers are allowed
  amazonInventorySyncPercentage: The Amazon Inventory Sync Percentage should be a positive integer between 1 and 100
  youHaveUnfinishedEditingProcess: You have unfinished editing in process, please save the edits or cancel the editing first.
  maximumInventoryValueOnlineStore: The maximum inventory value of online store must be higher than the alerting setting inventory value of the platform.
  alertingSettingInventoryValueOfPlatform: The alerting setting inventory value of the platform must be lower than the maximum inventory value of online store.
  successfullySet: Successfully Set!
  partiallySetSuccessfully: Partially Set Successfully!
  youAreDeleteing: You are deleting inventory alerting setting for the
  selectedCommodities: selected commodities .
  youAreSetingInventoryAlerts: You are setting inventory alerts for
  selectedItems: selected items
  onceDeletionWorks: Once deletion works, there will be no alerting to those inventories.
  haveNoSelectedAnyLongTail: You have not selected any long-tail commodity.
orderManagement:
  allLists: All lists
  pendingPayList: Pending payment
  pendingDeliveryList: Pending delivery
  deliveredList: Delivered
  canceledList: Canceled
  abnormalList: Abnormal orders
  download: Download
  orderId: Order No.
  deliveryTrackingNo: Logistics Tracking No.
  platformOrderNo: Order No. of Platform
  orderCreationTime: Order Creation Time
  exceptionDetails: Exception details
  start: Start
  finish: Finish
  dontEnterSpecialCharacters: Do not enter special characters
  orderNumberShouldStartWithDD: The order number should start with DD - and has been automatically modified!
  createNewOrder: Create New Order
  createNewOrdersInBatch: Create New Orders in Batch
  orderNo: Order No.
  systemOrders: System Orders(Manual)
  ebayOrder: eBay Order
  amazonOrder: Amazon Order
  itemCodeSubject: Item Code / Subject
  wholesaleAmount: Wholesale Amount
  actualSalesAmount: Actual Sales Amount
  createPayDelivered: Create/Pay/Delivered
  create: Create
  pay: Pay
  delivered: Delivered
  logistics: Logistics
  orderStatus: Order Status
  estimatedGrossProfit: Estimated gross profit
  reasonsOfCancellation: Reasons of Cancellation
  goToPay: Go to pay
  yourBalanceIs: Your balance is
  balanceWillBeUsed: Balance will be used for payment, are you sure to pay?
  orderPaymentHasExceeded: Order payment has exceeded
  daysAndCannotBeAppliedAftersalesService: days and cannot be applied for after-sales service
  orderDataIsIncorrect: This order data is incorrect
  otherPay: Pay
  yourBalanceIsNotEnough: Your balance is not enough to pay for the current order.
  successfullyPaid: Successfully Paid !
  failedPaid: Failed Paid!
  applyForTheAfterSales: Apply for the After-sales
  failedDeletion: Failed deletion.
  youCanUploadAfterFilling: You can upload after filling in as the template
  uploadTemplateFile: Upload Template file
  fileAnalysisCompletedStartingOrderCreating: File analysis completed! Starting order creating!
  fileAnalysisFailedFollowHints: File analysis failed, please follow the hints on the page
  networkAbnormality: Network abnormality!
  allOrdersSuccessfullyCreated: All orders successfully created!
  allOrdersInTheFileYouUploadedHaveBeenSuccessfullyCreated: All orders in the file you uploaded have been successfully created. You can continue uploading the file or click the left directory to go to the order list.
  partialOrdersCreatedSuccessfully: Partial Orders Created Successfully!
  partialOrdersHaveBeenCreatedFailedCreationAreAsFollows: Partial Orders have been created successfully, orders failed creation are as follows.
  lineNumber: Line Number
  externalOrderNo: External Order No.
  failureCauses: Error ‘Causes
  inventory: Inventory
  toCountry: To Country
  consigneeFirstName: Consignee’s First Name
  consigneeSurname: Consignee’s Surname
  consigneeEmail: Consignee’s E-mail
  fileAnalysisFailed: File analysis failed!
  fileYouUploadedFailedAnalysis: The file you uploaded failed analysis. The failure lines and reasons are as follows
  whetherCommodityExists: Whether commodity exists
  yes: Yes.
  no: No.
  filedsNeededModifyingSupplementing: Fields needed modifying and supplementing
  currentlyNo: Currently none
  creatingOrdersInBatch: Creating Orders in Batch
  the: The
  yThOutof: th out of
  xOrdersBeenCreated: orders have been created.
  youAreAddingAnOrder: You are adding an order
  skuFromOtherPlatform: SKU from other platform
  fillInSkuCodeUploadedToOtherPlatform: Please fill in the SKU code uploaded to other platform
  skuMapping: SKU mapping
  fillInTheCommodityItemCode: Please fill in the commodity item code
  orderQuantity: Order Quantity
  fillInTheOrderQuantity: Please fill in the order quantity
  totalAmount: Total Amount
  orderType: Oder Type
  selectOrderType: Please select the order type.
  fillInThePlatformOrderNumber: Please fill in the Third Party order number.
  consignee: Consignee
  firstName: First Name
  fillInTheFirstName: Please fill in the first name
  surname: Surname
  fillInTheSurname: Please fill in the surname
  phoneNo: Phone No.
  automaticallyAnalyzingPhoneNumber: Automatically analyzing phone number.
  fillInTheConsigneePhoneNumber: Please fill in the consignee’s phone number.
  countryCode: Country Code
  fillInACompleteEmailAddress: Please fill in a complete email address with no space
  country: Country
  selectACountry: Please select a country
  usa: USA
  postCode: Post Code
  fillInThePostCode: Please fill in the post code
  receivingRegion: Receiving Region
  enterStateOrProvince: Please enter state or province
  enterCity: Please enter city
  fillInAddressOne: Please fill in Address1
  fillinAddressTwo: Please fill in Address2
  eBayCertifiedWarehouse: eBay Certified Warehouse
  chooseWhetherEbayCertifiedWarehouse: Please choose whether eBay Certified Warehouse
  shi: YES.
  fou: NO.
  fillInTheEbayItemId: Please fill in the eBay Item ID
  fillIntheTransactionId: Please fill in the Transaction ID
  orderNote: Order Note
  fillInTheOrderNote: Please fill in the order note
  addOrder: Add Order
  manualOrder: Manual Order
  consigneeSurnameNFirstNameCannotExceedThirtyFive: The consignee's surname and first name cannot exceed 35 characters
  phoneNumberFormatNeededAnalysisAbnormal: The phone number format needed analysis is abnormal!
  numericalPartShouldBeTenDigitsNumber: The numerical part should be 10 digits number
  onlySpacesNumbersAndAllowed: Only spaces, numbers and ‘-’ are allowed
  inSufficientInventoryFaildToAdd: Insufficient inventory, failed to add order!
  successfullyAddedOrder: Successfully added order!
  fillInAPostalCode: Please fill in a postal code with five or nine digits
  maxInventory: Max inventory
  ebayItemIdLength: The eBay Item ID length generally does not exceed 20 digits.
  transactionIdLength: The Transaction ID length generally does not exceed 20 digits
  itemDoseNotExist: The item does not exist
  thirtyAndSplitInToAddressTwo: A single line address cannot exceed 30 characters, you can split it into Address2
  fiftyAndSplitInToAddressOne: A single line address cannot exceed 50 characters, you can split it into Address1
  enterPlatformSkuFirst: Please fill in the Third Party SKU first
  skuMatchingDoesNoteExist: Product with SKU matching does not exist!
  inSufficientInventorySelectedItems: Insufficient Inventory of selected Items!
