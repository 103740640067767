import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");

const remainingRouter = [
  {
    path: "/login",
    name: "login",
    component: () => import("/@/views/login/index.vue"),
    meta: {
      title: $t("menus.hslogin"),
      showLink: false,
      rank: 101
    }
  },
  {
    path: "/regist",
    name: "regist",
    component: () => import("/@/views/login/regist.vue"),
    meta: {
      title: $t("menus.hsregist"),
      showLink: false,
      rank: 101
    }
  },
  {
    path: "/welcomeNew",
    name: "WelcomeNew",
    component: () => import("/@/views/welcomeNew/welcomeNew.vue"),
    meta: {
      title: $t("menus.welcomeNew"),
      roles: ["user"],
      showLink: false
    }
  },
  {
    path: "/forgetPassword",
    name: "forgetPassword",
    component: () => import("../../views/login/forget.vue"),
    meta: {
      title: $t("menus.hsforgetPassword"),
      showLink: false,
      rank: 101
    }
  },
  {
    path: "/resultEbaySuccess",
    name: "resultEbaySuccess",
    component: () => import("/@/views/result/eBaySuccess.vue"),
    meta: {
      title: $t("menus.resultEbaySuccess"),
      showLink: false,
      rank: 111
    }
  },
  {
    path: "/resultAmazonSuccess",
    name: "resultAmazonSuccess",
    component: () => import("/@/views/result/resultAmazonSuccess.vue"),
    meta: {
      title: $t("menus.resultAmazonSuccess"),
      showLink: false,
      rank: 111
    }
  },
  {
    path: "/templatePreview",
    name: "templatePreview",
    component: () => import("/@/views/previews/templates/default.vue"),
    meta: {
      title: $t("menus.templatePreview"),
      showLink: false,
      rank: 111
    }
  },
  {
    path: "/noviceGuide",
    name: "noviceGuide",
    component: () => import("/@/views/previews/guides/novice.vue"),
    meta: {
      title: $t("menus.noviceGuide"),
      showLink: false,
      rank: 111
    }
  },
  {
    path: "/guidanceVideo",
    name: "guidanceVideo",
    component: () => import("/@/views/previews/guidanceVideo/videoMenu.vue"),
    meta: {
      title: $t("menus.guidanceVideo"),
      showLink: false,
      rank: 111
    }
  },
  {
    path: "/processIntroduction",
    name: "processIntroduction",
    component: () =>
      import("/@/views/previews/templates/processIntroduction.vue"),
    meta: {
      title: $t("menus.processIntroduction"),
      showLink: false,
      rank: 111
    }
  },
  {
    path: "/redirect",
    component: Layout,
    meta: {
      icon: "home-filled",
      title: $t("menus.hshome"),
      showLink: false,
      rank: 104
    },
    children: [
      {
        path: "/redirect/:path(.*)",
        name: "redirect",
        component: () => import("/@/layout/redirect.vue")
      }
    ]
  }
];

export default remainingRouter;
